@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold.e38241b1.woff2") format("woff2"), url("IBMPlexMono-Bold.ba755bed.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Cyrillic.500e2045.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Pi.bba00417.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Latin3.136a4d7d.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Latin2.361d8820.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Mono Bold), local(IBMPlexMono-Bold), url("IBMPlexMono-Bold-Latin1.df58aebe.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic.d88e8bff.woff2") format("woff2"), url("IBMPlexMono-BoldItalic.e0dca687.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Cyrillic.b1144c5d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Pi.fcdcbc4f.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Latin3.b118cc0f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Latin2.8e86f64e.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Mono Bold Italic), local(IBMPlexMono-BoldItalic), url("IBMPlexMono-BoldItalic-Latin1.9d2a303b.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight.4a38c3e8.woff2") format("woff2"), url("IBMPlexMono-ExtraLight.f337fc0f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Cyrillic.5b1bbb28.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Pi.791b36c7.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Latin3.98611400.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Latin2.a35d701c.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt), local(IBMPlexMono-ExtLt), url("IBMPlexMono-ExtraLight-Latin1.2ad813a2.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic.f26bab0e.woff2") format("woff2"), url("IBMPlexMono-ExtraLightItalic.67b0a3dd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Cyrillic.6f29c697.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Pi.a4df3486.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Latin3.68113ace.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Latin2.af40ec3f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Mono ExtLt Italic), local(IBMPlexMono-ExtLtItalic), url("IBMPlexMono-ExtraLightItalic-Latin1.75464bdd.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic.6caa7bcd.woff2") format("woff2"), url("IBMPlexMono-Italic.bd68fba1.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Cyrillic.225642d2.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Pi.5953710b.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Latin3.2dfc1a08.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Latin2.46edc1a4.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Mono Italic), local(IBMPlexMono-Italic), url("IBMPlexMono-Italic-Latin1.2d66e5e4.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light.b46c8511.woff2") format("woff2"), url("IBMPlexMono-Light.b02cab96.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Cyrillic.0f49790d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Pi.e69520ef.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Latin3.76437f8f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Latin2.8dff3285.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Mono Light), local(IBMPlexMono-Light), url("IBMPlexMono-Light-Latin1.64d115e4.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic.1b35386a.woff2") format("woff2"), url("IBMPlexMono-LightItalic.0a83cc5c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Cyrillic.5e1619c2.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Pi.e64c6ca3.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin3.7f4a898b.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin2.b5c9135f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin1.fdd9b655.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium.95a07c2d.woff2") format("woff2"), url("IBMPlexMono-Medium.aae1e722.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Cyrillic.93687e40.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Pi.32c5738c.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Latin3.15393095.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Latin2.d1b24cd5.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Mono Medm), local(IBMPlexMono-Medm), url("IBMPlexMono-Medium-Latin1.0961cee6.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic.ec8b1dda.woff2") format("woff2"), url("IBMPlexMono-MediumItalic.2d323ae0.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Cyrillic.59a96ebf.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Pi.22e8d9c8.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Latin3.87c3d673.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Latin2.9fa8c551.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Mono Medm Italic), local(IBMPlexMono-MedmItalic), url("IBMPlexMono-MediumItalic-Latin1.bb96d21e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular.14cc1386.woff2") format("woff2"), url("IBMPlexMono-Regular.377b6217.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Cyrillic.52120790.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Pi.17e13bc1.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Latin3.2fa9e6cd.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Latin2.9e78e34c.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Mono), local(IBMPlexMono), url("IBMPlexMono-Regular-Latin1.cb7f184e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold.b6ffd95b.woff2") format("woff2"), url("IBMPlexMono-SemiBold.b6d0b30c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Cyrillic.add58996.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Pi.16cc3e01.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Latin3.6b23b87f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Latin2.b93664b1.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld), local(IBMPlexMono-SmBld), url("IBMPlexMono-SemiBold-Latin1.1a735d80.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic.08b71865.woff2") format("woff2"), url("IBMPlexMono-SemiBoldItalic.10fc3693.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Cyrillic.30f63d78.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Pi.91f0a479.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Latin3.d31d888b.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Latin2.d2156562.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Mono SmBld Italic), local(IBMPlexMono-SmBldItalic), url("IBMPlexMono-SemiBoldItalic-Latin1.79560401.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text.ff0b0cc7.woff2") format("woff2"), url("IBMPlexMono-Text.2c50ea5b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Cyrillic.800886b9.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Pi.91fa45c8.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Latin3.faa473e0.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Latin2.24644b5a.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Mono Text), local(IBMPlexMono-Text), url("IBMPlexMono-Text-Latin1.b0bb4043.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic.beb02589.woff2") format("woff2"), url("IBMPlexMono-TextItalic.9896bdee.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Cyrillic.26f24eca.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Pi.15077257.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Latin3.c14a478e.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Latin2.336ff5df.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Mono Text Italic), local(IBMPlexMono-TextItalic), url("IBMPlexMono-TextItalic-Latin1.80fdf9f9.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin.f85ce1d8.woff2") format("woff2"), url("IBMPlexMono-Thin.8007510d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Cyrillic.43c91268.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Pi.2ad18db4.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Latin3.2e80367f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Latin2.e6faf561.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Mono Thin), local(IBMPlexMono-Thin), url("IBMPlexMono-Thin-Latin1.5b28ec3d.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic.b92e8d12.woff2") format("woff2"), url("IBMPlexMono-ThinItalic.bc66bd0b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Cyrillic.c882d020.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Pi.7563269d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Latin3.6ba3ddc8.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Latin2.b8b1beee.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Mono Thin Italic), local(IBMPlexMono-ThinItalic), url("IBMPlexMono-ThinItalic-Latin1.72fa0996.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold.b1175e59.woff2") format("woff2"), url("IBMPlexSans-Bold.c4b38bfe.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Cyrillic.fb85efca.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Pi.e6ce69be.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Latin3.3e789d23.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Latin2.66b4da7f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Latin1.7442eeef.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Bold), local(IBMPlexSans-Bold), url("IBMPlexSans-Bold-Greek.101988b0.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic.81ce314a.woff2") format("woff2"), url("IBMPlexSans-BoldItalic.bcf7dc48.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Cyrillic.41f1bd1a.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Pi.c40c2a17.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Latin3.12c3d51a.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Latin2.5a1db948.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Latin1.be72d227.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Bold Italic), local(IBMPlexSans-BoldItalic), url("IBMPlexSans-BoldItalic-Greek.e177aa19.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight.f3353df3.woff2") format("woff2"), url("IBMPlexSans-ExtraLight.ead02f56.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Cyrillic.889f867f.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Pi.42aa07c0.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Latin3.9645e56b.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Latin2.b656c9bb.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Latin1.0d4eb5e3.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt), local(IBMPlexSans-ExtLt), url("IBMPlexSans-ExtraLight-Greek.af1b45d6.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic.836de40a.woff2") format("woff2"), url("IBMPlexSans-ExtraLightItalic.f74a8dca.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Cyrillic.e94064dc.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Pi.cb1efbc2.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Latin3.11debd0e.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Latin2.87c69bb9.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Latin1.8000b77b.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans ExtLt Italic), local(IBMPlexSans-ExtLtItalic), url("IBMPlexSans-ExtraLightItalic-Greek.55359cb5.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic.0ee25785.woff2") format("woff2"), url("IBMPlexSans-Italic.ccec0ff9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Cyrillic.0bf1eea2.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Pi.f35ac58c.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Latin3.99865e78.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Latin2.018f3ed7.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Latin1.0a018146.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic-Greek.7ee36ac3.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light.8f026e73.woff2") format("woff2"), url("IBMPlexSans-Light.d53c7eb7.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Cyrillic.d4da1cbd.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Pi.f38acb6d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Latin3.800bdab0.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Latin2.b21123b0.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Latin1.56ca9e22.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Light), local(IBMPlexSans-Light), url("IBMPlexSans-Light-Greek.d923370c.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic.ba695177.woff2") format("woff2"), url("IBMPlexSans-LightItalic.029f8a89.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Cyrillic.3688ec4a.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Pi.6200e8cc.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Latin3.0fa78bf9.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Latin2.0a61ed88.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Latin1.bafc9b21.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Light Italic), local(IBMPlexSans-LightItalic), url("IBMPlexSans-LightItalic-Greek.a3dcb73f.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium.c9e07b8f.woff2") format("woff2"), url("IBMPlexSans-Medium.fe6cd995.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Cyrillic.f734ba3d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Pi.55dd82c2.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Latin3.156363c1.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Latin2.54613b0f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Latin1.23730c39.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Medm), local(IBMPlexSans-Medm), url("IBMPlexSans-Medium-Greek.a82cc889.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic.a42009ac.woff2") format("woff2"), url("IBMPlexSans-MediumItalic.d1b7222b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Cyrillic.42fcc80f.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Pi.1eb83bb1.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Latin3.de3f0df4.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Latin2.f4ccda78.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Latin1.3cd29c32.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Medm Italic), local(IBMPlexSans-MedmItalic), url("IBMPlexSans-MediumItalic-Greek.f8c639b8.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular.47db51d4.woff2") format("woff2"), url("IBMPlexSans-Regular.9fb5ce2b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Cyrillic.895e75d5.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Pi.27ee8e50.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Latin3.d13ae4b6.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Latin2.6aaad004.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Latin1.7be92051.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans), local(IBMPlexSans), url("IBMPlexSans-Regular-Greek.ce846b44.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold.6726be7a.woff2") format("woff2"), url("IBMPlexSans-SemiBold.2cf5b930.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Cyrillic.300400fa.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Pi.9841cb10.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Latin3.475311f6.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Latin2.3b84f302.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Latin1.21ff8425.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld), local(IBMPlexSans-SmBld), url("IBMPlexSans-SemiBold-Greek.65812327.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic.f309c95f.woff2") format("woff2"), url("IBMPlexSans-SemiBoldItalic.ca5522b8.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Cyrillic.e93c5674.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Pi.a8c33787.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Latin3.da47ca0a.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Latin2.d30a065b.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Latin1.986614a7.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans SmBld Italic), local(IBMPlexSans-SmBldItalic), url("IBMPlexSans-SemiBoldItalic-Greek.39c662ad.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text.77163891.woff2") format("woff2"), url("IBMPlexSans-Text.f2096607.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Cyrillic.22cea04e.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Pi.8325a75a.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Latin3.c939999c.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Latin2.d3f6b042.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Latin1.b5bd40ab.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Text), local(IBMPlexSans-Text), url("IBMPlexSans-Text-Greek.02329e6e.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic.d67fcb5c.woff2") format("woff2"), url("IBMPlexSans-TextItalic.b9824091.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Cyrillic.d0a96a23.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Pi.4e543d12.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Latin3.abba2d96.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Latin2.11e09fb3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Latin1.e72ef544.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Text Italic), local(IBMPlexSans-TextItalic), url("IBMPlexSans-TextItalic-Greek.6d013a8d.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin.0ff7ca6c.woff2") format("woff2"), url("IBMPlexSans-Thin.31c9ab7e.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Cyrillic.e73a189d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Pi.117254cc.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Latin3.9975e3cf.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Latin2.8751b7a0.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Latin1.e40c80ad.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thin), local(IBMPlexSans-Thin), url("IBMPlexSans-Thin-Greek.cb6564c2.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic.689f200d.woff2") format("woff2"), url("IBMPlexSans-ThinItalic.8602f049.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Cyrillic.417ad1b2.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Pi.89c2eb33.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Latin3.ddb35015.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Latin2.d87afe63.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Latin1.b86bdb6a.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Thin Italic), local(IBMPlexSans-ThinItalic), url("IBMPlexSans-ThinItalic-Greek.73f62a9c.woff2") format("woff2");
  unicode-range: U+384-38A, U+38C, U+38E-3A1, U+3A3-3CE;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold.9e25269b.woff2") format("woff2"), url("IBMPlexSansCondensed-Bold.80002b53.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Pi.4d1af75c.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Latin3.0df65747.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Latin2.2f75c744.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold), local(IBMPlexSansCond-Bold), url("IBMPlexSansCondensed-Bold-Latin1.f8ab5e3e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic.fcc1f152.woff2") format("woff2"), url("IBMPlexSansCondensed-BoldItalic.9603e0d4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Pi.c99852a4.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Latin3.00f19454.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Latin2.189612e2.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Sans Cond Bold Italic), local(IBMPlexSansCond-BoldItalic), url("IBMPlexSansCondensed-BoldItalic-Latin1.86fddc1e.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight.a4192834.woff2") format("woff2"), url("IBMPlexSansCondensed-ExtraLight.1ef95f19.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Pi.2f09c87d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Latin3.7e8da5e7.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Latin2.ad1d1186.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt), local(IBMPlexSansCond-ExtLt), url("IBMPlexSansCondensed-ExtraLight-Latin1.67e64ead.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic.c3482a51.woff2") format("woff2"), url("IBMPlexSansCondensed-ExtraLightItalic.e31aa0a3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Pi.8cc59938.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Latin3.fc837420.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Latin2.2ef6a6ba.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Sans Cond ExtLt Italic), local(IBMPlexSansCond-ExtLtItalic), url("IBMPlexSansCondensed-ExtraLightItalic-Latin1.f454c7fb.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic.1a0e4c92.woff2") format("woff2"), url("IBMPlexSansCondensed-Italic.2c88bb05.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Pi.c5c81173.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Latin3.25094d2f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Latin2.8937916e.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Sans Cond Italic), local(IBMPlexSansCond-Italic), url("IBMPlexSansCondensed-Italic-Latin1.8545b1cc.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light.393c7272.woff2") format("woff2"), url("IBMPlexSansCondensed-Light.7ae90f41.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Pi.79fdde43.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Latin3.bda84b40.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Latin2.264756b3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light), local(IBMPlexSansCond-Light), url("IBMPlexSansCondensed-Light-Latin1.308efc45.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic.bf8c66ec.woff2") format("woff2"), url("IBMPlexSansCondensed-LightItalic.469c52fe.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Pi.a4aea911.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Latin3.c4be8df4.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Latin2.b01a7264.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Sans Cond Light Italic), local(IBMPlexSansCond-LightItalic), url("IBMPlexSansCondensed-LightItalic-Latin1.a9c9cf6c.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium.be3d0fd9.woff2") format("woff2"), url("IBMPlexSansCondensed-Medium.3d380820.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Pi.00f8c035.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Latin3.4ee319c9.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Latin2.356e16d5.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm), local(IBMPlexSansCond-Medm), url("IBMPlexSansCondensed-Medium-Latin1.dbbc34ba.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic.51b7ee73.woff2") format("woff2"), url("IBMPlexSansCondensed-MediumItalic.f3b9e8c1.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Pi.ec42e86b.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Latin3.3bb1a496.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Latin2.7498a259.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Sans Cond Medm Italic), local(IBMPlexSansCond-MedmItalic), url("IBMPlexSansCondensed-MediumItalic-Latin1.b8644dce.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular.2e448e8d.woff2") format("woff2"), url("IBMPlexSansCondensed-Regular.789b3b1c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Pi.457ed915.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Latin3.fcf69881.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Latin2.e652eccf.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Cond), local(IBMPlexSansCond), url("IBMPlexSansCondensed-Regular-Latin1.bfc6ee7b.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold.8d782f04.woff2") format("woff2"), url("IBMPlexSansCondensed-SemiBold.536252d2.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Pi.67d7cca3.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Latin3.8b90f278.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Latin2.59c3effb.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld), local(IBMPlexSansCond-SmBld), url("IBMPlexSansCondensed-SemiBold-Latin1.6ca79dfc.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic.1acaf18f.woff2") format("woff2"), url("IBMPlexSansCondensed-SemiBoldItalic.bc5c15b6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Pi.ddd8b2d6.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Latin3.36329d7c.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Latin2.84419259.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Sans Cond SmBld Italic), local(IBMPlexSansCond-SmBldItalic), url("IBMPlexSansCondensed-SemiBoldItalic-Latin1.8bec3b94.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text.289e60b2.woff2") format("woff2"), url("IBMPlexSansCondensed-Text.86a517c9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Pi.225f31d1.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Latin3.03869ee3.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Latin2.b5e83fa8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text), local(IBMPlexSansCond-Text), url("IBMPlexSansCondensed-Text-Latin1.c29fd99f.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic.fdf17159.woff2") format("woff2"), url("IBMPlexSansCondensed-TextItalic.fe7f5953.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Pi.61a9243d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Latin3.26cece0f.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Latin2.24de1e8b.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Sans Cond Text Italic), local(IBMPlexSansCond-TextItalic), url("IBMPlexSansCondensed-TextItalic-Latin1.96f8417d.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin.893a8b8a.woff2") format("woff2"), url("IBMPlexSansCondensed-Thin.efe49bf9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Pi.b1fd2e13.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Latin3.33bceba3.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Latin2.af8e4fc3.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin), local(IBMPlexSansCond-Thin), url("IBMPlexSansCondensed-Thin-Latin1.1b3088d6.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic.5f03e1a7.woff2") format("woff2"), url("IBMPlexSansCondensed-ThinItalic.56b96033.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Pi.bd5bfc29.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Latin3.ba509760.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Latin2.c824bf84.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Condensed;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Sans Cond Thin Italic), local(IBMPlexSansCond-ThinItalic), url("IBMPlexSansCondensed-ThinItalic-Latin1.45fc8860.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Hebrew Bold), local(IBMPlexSansHebrew-Bold), url("IBMPlexSansHebrew-Bold.b7aa5eb3.woff2") format("woff2"), url("IBMPlexSansHebrew-Bold.a268146c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Hebrew ExtLt), local(IBMPlexSansHebrew-ExtLt), url("IBMPlexSansHebrew-ExtraLight.5860660d.woff2") format("woff2"), url("IBMPlexSansHebrew-ExtraLight.7a998d48.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Hebrew Light), local(IBMPlexSansHebrew-Light), url("IBMPlexSansHebrew-Light.762d8717.woff2") format("woff2"), url("IBMPlexSansHebrew-Light.2ba4fe4a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Hebrew Medm), local(IBMPlexSansHebrew-Medm), url("IBMPlexSansHebrew-Medium.488a0987.woff2") format("woff2"), url("IBMPlexSansHebrew-Medium.8cfe0121.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Hebrew), local(IBMPlexSansHebrew), url("IBMPlexSansHebrew-Regular.43795c23.woff2") format("woff2"), url("IBMPlexSansHebrew-Regular.e4d3abea.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Hebrew SmBld), local(IBMPlexSansHebrew-SmBld), url("IBMPlexSansHebrew-SemiBold.078571b2.woff2") format("woff2"), url("IBMPlexSansHebrew-SemiBold.5f46dfdd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Hebrew Text), local(IBMPlexSansHebrew-Text), url("IBMPlexSansHebrew-Text.5c1877f1.woff2") format("woff2"), url("IBMPlexSansHebrew-Text.7e98be03.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Hebrew;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Hebrew Thin), local(IBMPlexSansHebrew-Thin), url("IBMPlexSansHebrew-Thin.160e8d74.woff2") format("woff2"), url("IBMPlexSansHebrew-Thin.00977c16.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Devanagari Bold), local(IBMPlexSansDevanagari-Bold), url("IBMPlexSansDevanagari-Bold.9a896c7c.woff2") format("woff2"), url("IBMPlexSansDevanagari-Bold.faf5d106.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Devanagari ExtLt), local(IBMPlexSansDevanagari-ExtLt), url("IBMPlexSansDevanagari-ExtraLight.6b90c38f.woff2") format("woff2"), url("IBMPlexSansDevanagari-ExtraLight.b2a0c884.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Devanagari Light), local(IBMPlexSansDevanagari-Light), url("IBMPlexSansDevanagari-Light.b8a33866.woff2") format("woff2"), url("IBMPlexSansDevanagari-Light.b78e822a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Devanagari Medm), local(IBMPlexSansDevanagari-Medm), url("IBMPlexSansDevanagari-Medium.2f879d53.woff2") format("woff2"), url("IBMPlexSansDevanagari-Medium.ea41974c.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Devanagari), local(IBMPlexSansDevanagari), url("IBMPlexSansDevanagari-Regular.39960480.woff2") format("woff2"), url("IBMPlexSansDevanagari-Regular.427ede9d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Devanagari SmBld), local(IBMPlexSansDevanagari-SmBld), url("IBMPlexSansDevanagari-SemiBold.8b753353.woff2") format("woff2"), url("IBMPlexSansDevanagari-SemiBold.3ae35829.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Devanagari Text), local(IBMPlexSansDevanagari-Text), url("IBMPlexSansDevanagari-Text.4700d8d1.woff2") format("woff2"), url("IBMPlexSansDevanagari-Text.75badf10.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Devanagari;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Devanagari Thin), local(IBMPlexSansDevanagari-Thin), url("IBMPlexSansDevanagari-Thin.d2da94db.woff2") format("woff2"), url("IBMPlexSansDevanagari-Thin.c444d248.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Thai Bold), local(IBMPlexSansThai-Bold), url("IBMPlexSansThai-Bold.5b7d170c.woff2") format("woff2"), url("IBMPlexSansThai-Bold.11eb94ee.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Thai ExtLt), local(IBMPlexSansThai-ExtLt), url("IBMPlexSansThai-ExtraLight.f3f31bee.woff2") format("woff2"), url("IBMPlexSansThai-ExtraLight.ee198cc0.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Thai Light), local(IBMPlexSansThai-Light), url("IBMPlexSansThai-Light.bfd9e8cb.woff2") format("woff2"), url("IBMPlexSansThai-Light.c9365cd4.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Thai Medm), local(IBMPlexSansThai-Medm), url("IBMPlexSansThai-Medium.292e5672.woff2") format("woff2"), url("IBMPlexSansThai-Medium.12d1ba44.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Thai), local(IBMPlexSansThai), url("IBMPlexSansThai-Regular.1c257754.woff2") format("woff2"), url("IBMPlexSansThai-Regular.3a0e6ae6.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Thai SmBld), local(IBMPlexSansThai-SmBld), url("IBMPlexSansThai-SemiBold.9257fb03.woff2") format("woff2"), url("IBMPlexSansThai-SemiBold.0b076870.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Thai Text), local(IBMPlexSansThai-Text), url("IBMPlexSansThai-Text.743bd17c.woff2") format("woff2"), url("IBMPlexSansThai-Text.7d1ac78f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thai Thin), local(IBMPlexSansThai-Thin), url("IBMPlexSansThai-Thin.5014f662.woff2") format("woff2"), url("IBMPlexSansThai-Thin.aa115c0d.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Thai Looped Bold), local(IBMPlexSansThaiLooped-Bold), url("IBMPlexSansThaiLooped-Bold.0c667b0e.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Bold.64d2022a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Thai Looped ExtLt), local(IBMPlexSansThaiLooped-ExtLt), url("IBMPlexSansThaiLooped-ExtraLight.e6895009.woff2") format("woff2"), url("IBMPlexSansThaiLooped-ExtraLight.f4843a8b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Thai Looped Light), local(IBMPlexSansThaiLooped-Light), url("IBMPlexSansThaiLooped-Light.e664f1b1.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Light.599610d9.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Thai Looped Medm), local(IBMPlexSansThaiLooped-Medm), url("IBMPlexSansThaiLooped-Medium.d8cd35da.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Medium.73d6ee47.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Thai Looped), local(IBMPlexSansThaiLooped), url("IBMPlexSansThaiLooped-Regular.70479912.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Regular.2712c8aa.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Thai Looped SmBld), local(IBMPlexSansThaiLooped-SmBld), url("IBMPlexSansThaiLooped-SemiBold.d03fc0a8.woff2") format("woff2"), url("IBMPlexSansThaiLooped-SemiBold.05282d06.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Thai Looped Text), local(IBMPlexSansThaiLooped-Text), url("IBMPlexSansThaiLooped-Text.720313fe.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Text.fd895f67.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Thai Looped;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Thai Looped Thin), local(IBMPlexSansThaiLooped-Thin), url("IBMPlexSansThaiLooped-Thin.61c9fa52.woff2") format("woff2"), url("IBMPlexSansThaiLooped-Thin.77700e6a.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold.de516156.woff2") format("woff2"), url("IBMPlexSerif-Bold.81c97f92.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Cyrillic.974f79b1.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Pi.822584ec.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Latin3.8437b687.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Latin2.d048c7f1.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Serif Bold), local(IBMPlexSerif-Bold), url("IBMPlexSerif-Bold-Latin1.1b5ccd54.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic.64bb51d8.woff2") format("woff2"), url("IBMPlexSerif-BoldItalic.10f20554.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Cyrillic.01784150.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Pi.2ab2e02b.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Latin3.b3c9ed15.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Latin2.0a1cb745.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 700;
  src: local(IBM Plex Serif Bold Italic), local(IBMPlexSerif-BoldItalic), url("IBMPlexSerif-BoldItalic-Latin1.fbd37936.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight.8cb65bd7.woff2") format("woff2"), url("IBMPlexSerif-ExtraLight.fe696557.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Cyrillic.93a98898.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Pi.7266bd9e.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Latin3.0b0d0d3d.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Latin2.ba2be53a.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt), local(IBMPlexSerif-ExtLt), url("IBMPlexSerif-ExtraLight-Latin1.5a69c441.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic.9804356e.woff2") format("woff2"), url("IBMPlexSerif-ExtraLightItalic.986489c5.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Cyrillic.2e049e43.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Pi.57a63f08.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Latin3.78cf1a71.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Latin2.01f2a03d.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 200;
  src: local(IBM Plex Serif ExtLt Italic), local(IBMPlexSerif-ExtLtItalic), url("IBMPlexSerif-ExtraLightItalic-Latin1.6a6b814f.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic.74d24728.woff2") format("woff2"), url("IBMPlexSerif-Italic.b50cd759.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Cyrillic.21da0419.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Pi.f94c52d7.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Latin3.4d4904e9.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Latin2.b3cf372a.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 400;
  src: local(IBM Plex Serif Italic), local(IBMPlexSerif-Italic), url("IBMPlexSerif-Italic-Latin1.9a1f4b52.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light.312e6752.woff2") format("woff2"), url("IBMPlexSerif-Light.08d8a180.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Cyrillic.f0939d31.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Pi.4527202d.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Latin3.a7113c50.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Latin2.d75e1c68.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Serif Light), local(IBMPlexSerif-Light), url("IBMPlexSerif-Light-Latin1.2ac07ee3.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic.38bdf662.woff2") format("woff2"), url("IBMPlexSerif-LightItalic.601c07fa.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Cyrillic.55103b11.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Pi.22ab3b16.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Latin3.b0f11d78.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Latin2.e3982e07.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Serif Light Italic), local(IBMPlexSerif-LightItalic), url("IBMPlexSerif-LightItalic-Latin1.b3cbd62d.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium.3519d28d.woff2") format("woff2"), url("IBMPlexSerif-Medium.604448fa.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Cyrillic.50c899ef.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Pi.64dd090b.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Latin3.c27f0bbd.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Latin2.2360fcb5.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Serif Medm), local(IBMPlexSerif-Medm), url("IBMPlexSerif-Medium-Latin1.24487a99.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic.cacce34e.woff2") format("woff2"), url("IBMPlexSerif-MediumItalic.db56d271.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Cyrillic.9e3aea1d.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Pi.7a06f23e.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Latin3.ea4c1b5c.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Latin2.12b75496.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 500;
  src: local(IBM Plex Serif Medm Italic), local(IBMPlexSerif-MedmItalic), url("IBMPlexSerif-MediumItalic-Latin1.ad422661.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular.2f031103.woff2") format("woff2"), url("IBMPlexSerif-Regular.b6c4b347.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Cyrillic.7dbd5bcf.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Pi.4e847982.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Latin3.71cd6973.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Latin2.b5559195.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Serif), local(IBMPlexSerif), url("IBMPlexSerif-Regular-Latin1.ab56eafa.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold.971381c8.woff2") format("woff2"), url("IBMPlexSerif-SemiBold.c0619b97.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Cyrillic.464e49d3.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Pi.ec918f72.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Latin3.7722ee4c.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Latin2.d76be97f.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld), local(IBMPlexSerif-SmBld), url("IBMPlexSerif-SemiBold-Latin1.db9bdf27.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic.649233b3.woff2") format("woff2"), url("IBMPlexSerif-SemiBoldItalic.d62536ba.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Cyrillic.3e35d3c3.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Pi.a2675ee5.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Latin3.d60cd1ec.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Latin2.6aac90c0.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 600;
  src: local(IBM Plex Serif SmBld Italic), local(IBMPlexSerif-SmBldItalic), url("IBMPlexSerif-SemiBoldItalic-Latin1.57cd396c.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text.96c15a74.woff2") format("woff2"), url("IBMPlexSerif-Text.504ed4b1.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Cyrillic.f7c078cd.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Pi.afc2e6f9.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Latin3.56b746e6.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Latin2.2f004db9.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Serif Text), local(IBMPlexSerif-Text), url("IBMPlexSerif-Text-Latin1.a67883dd.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic.1dcee4d7.woff2") format("woff2"), url("IBMPlexSerif-TextItalic.597ec286.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Cyrillic.9378fb22.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Pi.88eba6b7.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Latin3.68e725b9.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Latin2.a2451056.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 450;
  src: local(IBM Plex Serif Text Italic), local(IBMPlexSerif-TextItalic), url("IBMPlexSerif-TextItalic-Latin1.2b4b6c84.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin.40fb32ae.woff2") format("woff2"), url("IBMPlexSerif-Thin.64eb8ca3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Cyrillic.4959ea0f.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Pi.f636e9a3.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Latin3.21a9ab93.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Latin2.79d06335.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Serif Thin), local(IBMPlexSerif-Thin), url("IBMPlexSerif-Thin-Latin1.0b757436.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic.8710bff2.woff2") format("woff2"), url("IBMPlexSerif-ThinItalic.e47b06cb.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Cyrillic.793ae0fe.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Pi.776cdccb.woff2") format("woff2");
  unicode-range: U+E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Latin3.290d39fa.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Latin2.ae17a7db.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Serif;
  font-style: italic;
  font-weight: 100;
  src: local(IBM Plex Serif Thin Italic), local(IBMPlexSerif-ThinItalic), url("IBMPlexSerif-ThinItalic-Latin1.321d8070.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-A3, U+A4-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 700;
  src: local(IBM Plex Sans Arabic Bold), local(IBMPlexSansArabic-Bold), url("IBMPlexSansArabic-Bold.9cdb56c0.woff2") format("woff2"), url("IBMPlexSansArabic-Bold.da142fbb.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 200;
  src: local(IBM Plex Sans Arabic ExtLt), local(IBMPlexSansArabic-ExtLt), url("IBMPlexSansArabic-ExtraLight.ded76f5f.woff2") format("woff2"), url("IBMPlexSansArabic-ExtraLight.251fecdd.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 300;
  src: local(IBM Plex Sans Arabic Light), local(IBMPlexSansArabic-Light), url("IBMPlexSansArabic-Light.ec930c66.woff2") format("woff2"), url("IBMPlexSansArabic-Light.98884944.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 500;
  src: local(IBM Plex Sans Arabic Medm), local(IBMPlexSansArabic-Medm), url("IBMPlexSansArabic-Medium.6bc56eea.woff2") format("woff2"), url("IBMPlexSansArabic-Medium.98f1cfd3.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 400;
  src: local(IBM Plex Sans Arabic), local(IBMPlexSansArabic), url("IBMPlexSansArabic-Regular.212d9dfc.woff2") format("woff2"), url("IBMPlexSansArabic-Regular.9f58f60f.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 600;
  src: local(IBM Plex Sans Arabic SmBld), local(IBMPlexSansArabic-SmBld), url("IBMPlexSansArabic-SemiBold.174d9344.woff2") format("woff2"), url("IBMPlexSansArabic-SemiBold.0325e1a0.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 450;
  src: local(IBM Plex Sans Arabic Text), local(IBMPlexSansArabic-Text), url("IBMPlexSansArabic-Text.07dc88ca.woff2") format("woff2"), url("IBMPlexSansArabic-Text.787b8a3b.woff") format("woff");
}

@font-face {
  font-family: IBM Plex Sans Arabic;
  font-style: normal;
  font-weight: 100;
  src: local(IBM Plex Sans Arabic Thin), local(IBMPlexSansArabic-Thin), url("IBMPlexSansArabic-Thin.60e6e077.woff2") format("woff2"), url("IBMPlexSansArabic-Thin.2aad9479.woff") format("woff");
}



/*# sourceMappingURL=app.017081d4.css.map */
